import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';
import { throttle } from 'throttle-debounce';
import { useIsMobile } from 'src/utils';
import {
  ScrollTopContext,
  ScrollTopDispatchContext,
} from 'src/contexts/scroll-top-context';
import { BrandType } from 'types/custom-types';

type Props = {
  className?: string;
  isSaveScrollTop?: boolean;
  postType?: BrandType | 'post';
  children: React.ReactNode;
};

export const Item: React.FC<Props> = ({
  className,
  isSaveScrollTop = false,
  postType,
  children,
}: Props) => {
  const { build, birth, seed, post } = React.useContext(ScrollTopContext);
  const { dispatch } = React.useContext(ScrollTopDispatchContext);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();
  React.useEffect(() => {
    const targetDom = ref.current;
    function onScroll() {
      if (!isMobile && isSaveScrollTop && targetDom && postType && dispatch) {
        const scrollTop = targetDom.scrollTop;
        const payload =
          postType === 'build'
            ? { build: scrollTop }
            : postType === 'birth'
            ? { birth: scrollTop }
            : postType === 'seed'
            ? { seed: scrollTop }
            : postType === 'post'
            ? { post: scrollTop }
            : null;
        if (payload) {
          dispatch({ type: 'set', payload });
        }
      }
    }
    const debouncedOnScroll = throttle(300, onScroll);
    if (!isMobile && isSaveScrollTop && targetDom && postType) {
      targetDom.scrollTop =
        postType === 'build'
          ? build
          : postType === 'birth'
          ? birth
          : postType === 'seed'
          ? seed
          : postType === 'post'
          ? post
          : 0;
      targetDom.addEventListener('scroll', debouncedOnScroll);
      targetDom.classList.add('show');
    }
    return () => {
      if (!isMobile && isSaveScrollTop && targetDom) {
        targetDom.removeEventListener('scroll', debouncedOnScroll);
      }
    };
    // NOTE:
    // あまり良く無いが、マウント時だけに適用
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  return (
    <Wrapper
      className={`${className || ''} ${
        isSaveScrollTop ? 'save-scroll-top' : ''
      }`}
      ref={ref}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &.order-1 {
    order: 1;
  }
  &.order-2 {
    order: 2;
  }
  &.order-3 {
    order: 3;
  }
  ${media.ipadHorizontalOrMore`
    flex-basis: calc(100% / 3);
    height: calc(100vh - ${({ theme }) => theme.headerHeight.pc}px);
    overflow: auto;
    &.order-2 {
      order: 2;
      border-left: ${({ theme }) => theme.border}
    }
    &.order-3 {
      order: 3;
      border-left: ${({ theme }) => theme.border}
    }
    &.save-scroll-top {
      > * {
        opacity: 0;
      }
      &.show {
        > * {
          opacity: 1;
        }
      }
    }
  `}
`;

export default Item;
