import React from 'react';
import { LangKeyType } from 'custom-types';

export const initialValue = {
  slug: '/',
  langKey: 'ja' as LangKeyType,
  isHome: true,
};

type SlugContextType = typeof initialValue;

export const SlugContext = React.createContext<SlugContextType>(initialValue);

export default SlugContext;
