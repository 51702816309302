import React from 'react';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import { linkResolver } from './link-resolver';
import { Anchor } from 'src/components/common/atoms/anchor';

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer: HTMLSerializer<any> = function (
  type,
  element,
  content,
  children,
  key
) {
  let props = {};
  switch (type) {
    // Add a class to hyperlinks
    case Elements.hyperlink: {
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign(targetAttr, relAttr);
      return (
        <Anchor
          key={key}
          to={element.data.url || linkResolver(element.data)}
          {...props}
        >
          {children}
        </Anchor>
      );
      // return React.createElement('a', propsWithUniqueKey(props, key), children);
    }
    // Return null to stick with the default behavior
    default: {
      return '';
    }
  }
};
