import * as React from 'react';
import 'focus-visible';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { media } from 'src/styles';
import { GlobalStyle } from 'src/styles';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { ProviderManager, Header, Footer } from 'src/components/common';

type Props = PageProps;

function useProviderManagerProps(pathname: string) {
  return React.useMemo(() => {
    const langKey = pathname?.startsWith('/en')
      ? ('en' as const)
      : ('ja' as const);
    const isHome = pathname === '/' || /^\/en\/?$/.exec(pathname) !== null;
    const slug = pathname
      ? pathname?.endsWith('/')
        ? pathname
        : `${pathname}/`
      : '/';
    return {
      langKey,
      isHome,
      slug,
    };
  }, [pathname]);
}

export const Layouts: React.FC<Props> = ({ location, children }: Props) => {
  const { pathname } = location;
  const providerManagerProps = useProviderManagerProps(pathname);
  const { langKey } = providerManagerProps;
  return (
    <>
      <SkipNavLink />
      <GlobalStyle />
      <ProviderManager {...providerManagerProps}>
        <Page>
          <StyledHeader />
          <SkipNavContent />
          <Main className={`lang-${langKey}`}>{children}</Main>
          <Footer />
        </Page>
      </ProviderManager>
    </>
  );
};

const StyledHeader = styled(Header)`
  position: sticky;
  z-index: 100;
  top: 0;
`;

const Main = styled.main`
  flex: 1;
  line-height: ${({ theme }) => theme.lineHeight.en};
  font-size: ${({ theme }) => theme.font.size.normal};
  &.lang-ja {
    font-feature-settings: 'pkna';
    text-align: justify;
    line-height: ${({ theme }) => theme.lineHeight.ja};
  }
  ${media.lessThanIpadHorizontal`
    /* NOTE:
    flexで伸ばした場合、子のheight100%が反映されないので、以下を指定。
    例えば、スマホのnewsの詳細ページの記事本文が短い場合。
    http://localhost:8888/news/210321_event/
    post/content/content.tsx
    common/content-column/content-column.tsx
    */
    display: flex;
    flex-direction: column;
  `}
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default Layouts;
