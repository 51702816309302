import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useSlug, useIsMobile } from 'src/utils';
import { MenuList, Props as MenuListProps } from './menu-list';
import { GetSeasonsForNavigationQuery } from 'graphql-types';

type Props = {
  id: string;
  className?: string;
};

type QueryProps = GetSeasonsForNavigationQuery;

export const staticQuery = graphql`
  query GetSeasonsForNavigation {
    prismic {
      seasonsJa: allSeasons(lang: "ja-jp", sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
          }
        }
      }
      seasonsEn: allSeasons(lang: "en-us", sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
          }
        }
      }
    }
  }
`;

const menuData = [
  {
    label: 'home',
    uri: '/',
  },
  {
    label: 'Build',
    uri: '/build/',
    brandName: 'build',
    startSeason: '2021fw',
  },
  {
    label: 'Birth',
    uri: '/birth/',
    brandName: 'birth',
  },
  {
    label: 'Seed',
    uri: '/seed/',
    brandName: 'seed',
  },
  {
    label: 'News',
    uri: '/news/',
  },
  {
    label: 'Stores',
    uri: '/stores/',
  },
  {
    label: 'Process',
    uri: '/process/',
  },
];

function useMenus({
  langKey,
  data,
  seasonsJa,
  seasonsEn,
}: {
  langKey: string;
  data: typeof menuData;
  seasonsJa: GetSeasonsForNavigationQuery['prismic']['seasonsJa'];
  seasonsEn: GetSeasonsForNavigationQuery['prismic']['seasonsEn'];
}) {
  const isMobile = useIsMobile();
  return React.useMemo(() => {
    const seasons = langKey === 'ja' ? seasonsJa : seasonsEn;
    if (!data) {
      return null;
    }
    return data
      .filter((item) => {
        if (isMobile) {
          return item;
        } else {
          return item.label !== 'home';
        }
      })
      .map(({ label, uri, brandName, startSeason }) => {
        let subList: MenuListProps['list'] = [];
        const match = new RegExp(/build|birth|seed/).exec(uri);
        if (match && seasons.edges) {
          const brandName = match[0];
          subList = seasons.edges
            .filter((edge) => {
              if (!startSeason) {
                return true;
              }
              const matchStartSeason = /(\d{4})(\w{2})/.exec(startSeason);
              if (!matchStartSeason) {
                return true;
              }
              const seasonSlug = edge?.node._meta.uid;
              if (!seasonSlug) {
                return false;
              }
              const match = /(\d{4})(\w{2})/.exec(seasonSlug);
              if (!match) {
                return false;
              }
              const [, seasonYear, seasonText] = match;
              const [, startSeasonYear, startSeasonText] = matchStartSeason;
              if (seasonYear >= startSeasonYear) {
                if (seasonYear === startSeasonYear) {
                  if (startSeasonText === 'fw' && seasonText === 'ss') {
                    return false;
                  }
                }
                return true;
              } else {
                return false;
              }
            })
            .map((edge) => {
              const defaultPath = `/${brandName}/${edge?.node._meta.uid}/`;
              return {
                label: edge?.node.title
                  ? RichText.asText(edge?.node.title)
                  : '',
                uri:
                  langKey === 'ja' ? defaultPath : `/${langKey}${defaultPath}`,
              };
            });
        }
        return {
          label,
          uri: langKey === 'ja' ? uri : `/${langKey}${uri}`,
          brandName: brandName || undefined,
          subList: subList.length ? subList : undefined,
        };
      });
  }, [isMobile, langKey, data, seasonsJa, seasonsEn]);
}

export const Navigation: React.FC<Props> = ({ id, className }: Props) => {
  const { langKey } = useSlug();
  const {
    prismic: { seasonsJa, seasonsEn },
  } = useStaticQuery<QueryProps>(staticQuery);
  const menus = useMenus({ langKey, data: menuData, seasonsJa, seasonsEn });
  return (
    <nav id={id} className={className || ''} role="navigation" lang="en">
      <MenuList list={menus} />
    </nav>
  );
};

export default Navigation;
