import * as React from 'react';
import styled from 'styled-components';
import { Margin } from 'src/components/common';
import { Navigation } from './navigation';

type Props = {
  className?: string;
};

export const Footer: React.FC<Props> = ({ className }: Props) => (
  <Wrapper role="contentinfo" className={className || ''}>
    <Margin>
      <Navigation />
    </Margin>
  </Wrapper>
);

const Wrapper = styled.footer`
  border: ${({ theme }) => theme.border};
`;

export default Footer;
