import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';

type Props = {
  className?: string;
  mobileTitle?: string;
  closeButton?: React.ReactNode;
  children: React.ReactNode;
};

export const ModalContent: React.FC<Props> = ({
  className,
  mobileTitle,
  closeButton: CloseButton,
  children,
}: Props) => (
  <Wrapper className={className ?? ''}>
    {(mobileTitle || CloseButton) && (
      <MobileHeader>
        {mobileTitle && <MobileTitle>{mobileTitle}</MobileTitle>}
        {CloseButton && CloseButton}
      </MobileHeader>
    )}
    {children}
  </Wrapper>
);

const MobileTitle = styled.p`
  font-family: ${({ theme }) => theme.font.family.helvetica};
  letter-spacing: ${({ theme }) => theme.enLetterSpacing};
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
`;

const MobileHeader = styled.div`
  position: sticky;
  z-index: 10;
  top: calc(${({ theme }) => theme.headerHeight.sp}px + 4px);
  left: 0;
  background-color: white;
  width: 100%;
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  padding-bottom: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.ipadHorizontalOrMore`
    display: none;
  `}
`;

const Wrapper = styled.div`
  border: ${({ theme }) => theme.border};
  ${media.lessThanIpadHorizontal`
    position: relative;
    margin-top: ${({ theme }) => theme.headerHeight.sp}px;
    min-height: calc(100vh - ${({ theme }) => theme.headerHeight.sp}px);
  `}
`;

export default ModalContent;
