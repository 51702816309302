import * as React from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/common/atoms';
import PlayImage from 'src/images/button_play.inline.svg';
import PauseImage from 'src/images/button_pause.inline.svg';

type Props = {
  className?: string;
  isPlay: boolean;
  onPlay: () => void;
  onPause: () => void;
  targetId: string;
};

export const Controller: React.FC<Props> = ({
  className,
  isPlay,
  onPlay,
  onPause,
  targetId,
}: Props) => (
  <Wrapper className={className || ''}>
    {!isPlay && (
      <StyledButton
        onClick={onPlay}
        aria-controls={targetId}
        aria-label="Play this video"
      >
        <ImageWrapper>
          <PlayImage />
        </ImageWrapper>
      </StyledButton>
    )}
    {isPlay && (
      <StyledButton
        onClick={onPause}
        aria-controls={targetId}
        aria-label="Pause this video"
      >
        <ImageWrapper>
          <PauseImage />
        </ImageWrapper>
      </StyledButton>
    )}
  </Wrapper>
);

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  opacity: 0;
  &:hover,
  &.focus-visible {
    opacity: 1;
  }
  &:focus-visible {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export default Controller;
