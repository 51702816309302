import 'styled-components';

export const theme = {
  font: {
    family: {
      main:
        '"Helvetica Neue", Helvetica, Arial, a-otf-midashi-go-mb31-pr6n, YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif',
      helvetica: '"Helvetica Neue", Helvetica, Arial, Verdana, Roboto',
    },
    size: {
      xxxlarge: '5.6rem',
      xxlarge: '2.8rem',
      xlarge: '2.2rem',
      large: '2rem',
      normal: '1.5rem',
      small: '1.3rem',
      xsmall: '1.2rem',
    },
  },
  lineHeight: {
    ja: 1.5,
    en: 1.4,
  },
  enLetterSpacing: '-0.05em',
  colors: {
    build: '#12566f',
    birth: '#006F34',
    seed: '#954F2A',
    lightGray: '#f7f7f7',
  },
  border: '2px solid black',
  headerHeight: {
    sp: 47,
    pc: 54,
  },
  structure: {
    margin: {
      xSmall: 14,
      xSmallWithText: 12,
      xSmallWithLargeText: 9,
      small: 18,
      smallWithText: 15,
      medium: 28,
      mediumWithText: 25,
      mediumWithLargeText: 22,
      large: 42,
      largeWithText: 35,
    },
    siteMargin: {
      vertical: {
        sp: 18,
        pc: 20,
      },
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    large: 1200,
    ipadProHorizontal: 1300,
    siteMaxWidth: 1600,
  },
};

type ThemeType = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}

export default theme;
