import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { htmlSerializer } from 'src/utils';
import { WithoutMargin } from 'src/components/common';
import { Img } from 'src/components/common/atoms';
import {
  PostSingleContentTextFragment,
  PostSingleContentImageFragment,
  BrandContentTextFragment,
  BrandContentImageFragment,
} from 'graphql-types';

type Props = {
  className?: string;
  content: (
    | PostSingleContentTextFragment
    | PostSingleContentImageFragment
    | BrandContentTextFragment
    | BrandContentImageFragment
  )[];
};

export const SliceContent: React.FC<Props> = ({
  className,
  content,
}: Props) => (
  <Wrapper className={className || ''}>
    {content.map((item, index) => (
      <React.Fragment key={`slice-content-${index}`}>
        {item.type === 'text' && 'primary' in item && (
          <RichText
            render={item.primary?.text}
            htmlSerializer={htmlSerializer}
          />
        )}
        {item.type === 'image_gallery' && 'fields' in item && (
          <>
            {item.fields?.map(
              (image, imageIndex) =>
                image?.gallery_imageSharp && (
                  <ImageWrapper
                    key={`slice-content-image-${index}-${imageIndex}`}
                  >
                    <WithoutMargin>
                      <Img
                        fluid={image.gallery_imageSharp.childImageSharp?.fluid}
                        alt={image.gallery_image?.alt || ''}
                        sizes="(min-width: 1024px) calc(100vw / 3), 100vw"
                      />
                    </WithoutMargin>
                  </ImageWrapper>
                )
            )}
          </>
        )}
      </React.Fragment>
    ))}
  </Wrapper>
);

const ImageWrapper = styled.div``;

const Wrapper = styled.div`
  > * + * {
    margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
  }
  > * + ${ImageWrapper} {
    margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
    margin-bottom: ${({ theme }) => theme.structure.margin.smallWithText}px;
  }
`;

export default SliceContent;
