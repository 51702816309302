import * as React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  src: string;
  alt: string;
};

export const BrandLogo: React.FC<Props> = ({ className, src, alt }: Props) => (
  <Wrapper className={className || ''}>
    <StyledImg src={src} alt={alt} />
  </Wrapper>
);

const StyledImg = styled.img`
  width: auto;
  height: 100%;
`;

const Wrapper = styled.figure`
  height: 190px;
`;

export default BrandLogo;
