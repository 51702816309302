import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useIsMobile } from 'src/utils';
import { ScreenReaderText } from 'src/components/common';
import { Anchor, Button } from 'src/components/common/atoms';
import LogoLarge from 'src/images/logo_large.inline.svg';

type AnchorProps = {
  label: string;
  uri: string;
  brandName?: string;
  subList?: AnchorProps[];
};

export type Props = AnchorProps & {
  className?: string;
};

export const MenuAnchor: React.FC<Props> = ({
  className,
  label,
  uri,
  brandName,
  subList,
}: Props) => {
  const isMobile = useIsMobile();
  const [showSubList, setShowSubList] = React.useState(false);
  const handleClick = React.useCallback<React.MouseEventHandler>(() => {
    if (isMobile) {
      setShowSubList(!showSubList);
    }
  }, [isMobile, showSubList]);
  const homeRegex = new RegExp('home');
  return (
    <Wrapper
      className={`${className || ''} ${brandName || ''} ${
        showSubList ? 'show-sub-list' : ''
      }`}
    >
      {!subList && (
        <Anchor to={uri}>
          {homeRegex.exec(label) && (
            <MobileHome>
              <ScreenReaderText>{label}</ScreenReaderText>
              <LogoLarge />
            </MobileHome>
          )}
          {!homeRegex.exec(label) && label}
        </Anchor>
      )}
      {subList && (
        <>
          <StyledButton
            onClick={handleClick}
            aria-controls={`${brandName}-submeum`}
          >
            {label}
          </StyledButton>
          <SubList>
            {subList.map(({ label: subLabel, uri: subUri }, index) => (
              <SubListItem
                key={`${label}-${index}`}
                id={`${brandName}-submeum`}
              >
                <Anchor to={subUri}>{subLabel}</Anchor>
              </SubListItem>
            ))}
          </SubList>
        </>
      )}
    </Wrapper>
  );
};

const MobileHome = styled.div`
  width: 125px;
`;

const SubListItem = styled.li`
  & + & {
    margin-top: 0.5em;
  }
`;

const SubList = styled.ul`
  position: absolute;
  z-index: 1;
  display: none;
  width: 150px;
  ${media.lessThanIpadHorizontal`
    top: 0;
    left: 130px;
  `}
`;

const StyledButton = styled(Button)`
  padding: 0;
  border: none;
  text-transform: uppercase;
  ${media.ipadHorizontalOrMore`
    & + ${SubList} {
      padding-top: 0.5em;
    }
  `}
`;

const Wrapper = styled.div`
  position: relative;
  text-transform: uppercase;
  &:hover,
  &:focus-within,
  &.show-sub-list {
    &.build {
      color: ${({ theme }) => theme.colors.build};
    }
    &.birth {
      color: ${({ theme }) => theme.colors.birth};
    }
    &.seed {
      color: ${({ theme }) => theme.colors.seed};
    }
    ${SubList} {
      display: block;
    }
  }
`;

export default MenuAnchor;
