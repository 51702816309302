import styled from 'styled-components';
import { media } from 'src/styles';

export const Margin = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  ${media.ipadHorizontalOrMore`
    padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.pc}px;
    padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.pc}px;
  `}
`;

export const WithoutMargin = styled.div`
  margin-left: calc(
    ${({ theme }) => theme.structure.siteMargin.vertical.sp}px * -1
  );
  margin-right: calc(
    ${({ theme }) => theme.structure.siteMargin.vertical.sp}px * -1
  );
  ${media.ipadHorizontalOrMore`
    margin-left: calc(
    ${({ theme }) => theme.structure.siteMargin.vertical.pc}px * -1
  );
    margin-right:calc(
    ${({ theme }) => theme.structure.siteMargin.vertical.pc}px * -1
  );
  `}
`;
