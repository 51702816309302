import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme as styleTheme } from 'src/styles';
import { SlugContext } from 'src/contexts/slug-context';
import {
  ScrollTopContext,
  ScrollTopDispatchContext,
  initialValue as scrollTopInitialValue,
  ScrollTopReducer,
} from 'src/contexts/scroll-top-context';
import {
  TranslationContext,
  TranslationDispatchContext,
  initialValue as translationInitialValue,
  TranslationReducer,
} from 'src/contexts/translation-context';
import { LangKeyType } from 'custom-types';

type Props = {
  isHome: boolean;
  slug: string;
  langKey: LangKeyType;
  children: React.ReactNode;
};

export const ProviderManager: React.FC<Props> = ({
  isHome,
  slug,
  langKey,
  children,
}: Props) => {
  const [scrollTop, dispatchScrollTop] = React.useReducer(
    ScrollTopReducer,
    scrollTopInitialValue
  );
  const [translation, dispatchTranslation] = React.useReducer(
    TranslationReducer,
    translationInitialValue
  );
  return (
    <ThemeProvider theme={styleTheme}>
      <SlugContext.Provider value={{ slug, langKey, isHome }}>
        <ScrollTopContext.Provider value={scrollTop}>
          <ScrollTopDispatchContext.Provider
            value={{ dispatch: dispatchScrollTop }}
          >
            <TranslationContext.Provider value={translation}>
              <TranslationDispatchContext.Provider
                value={{ dispatch: dispatchTranslation }}
              >
                {children}
              </TranslationDispatchContext.Provider>
            </TranslationContext.Provider>
          </ScrollTopDispatchContext.Provider>
        </ScrollTopContext.Provider>
      </SlugContext.Provider>
    </ThemeProvider>
  );
};

export default ProviderManager;
