import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useSlug } from 'src/utils';
import { Margin } from 'src/components/common/margin';
import { Item } from './item';
import { BrandType } from 'types/custom-types';

type Props = {
  className?: string;
  items: {
    component: React.ReactNode | null;
    order?: number;
    hasMarginTop?: boolean;
    hasMarginBottom?: boolean;
  }[];
  postType?: BrandType | 'post';
};

export const ContentColumn: React.FC<Props> = ({
  className,
  items,
  postType,
}: Props) => {
  const { isHome } = useSlug();
  return (
    <Wrapper className={`${className || ''} ${postType || ''}`}>
      {items.map(
        (
          { component, order, hasMarginTop = true, hasMarginBottom = true },
          index
        ) => (
          <StyledItem
            key={`column-item-${index}`}
            className={`${order ? `order-${order}` : ''} ${
              isHome ? 'home' : ''
            }`}
            isSaveScrollTop={index === 1 && postType !== undefined}
            postType={postType || undefined}
          >
            <MarginVertical
              className={`${hasMarginTop ? 'has-margin-top' : ''} ${
                hasMarginBottom ? 'has-margin-bottom' : ''
              }`}
            >
              <Margin>{component ?? <div />}</Margin>
            </MarginVertical>
          </StyledItem>
        )
      )}
    </Wrapper>
  );
};

const StyledItem = styled(Item)`
  ${media.lessThanIpadHorizontal`
    &.home {
      margin-top: ${({ theme }) => theme.structure.margin.largeWithText}px;
    }
  `}
`;

const MarginVertical = styled.div`
  &.has-margin-top {
    padding-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  }
  ${media.ipadHorizontalOrMore`
    &.has-margin-top {
      padding-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
    }
    &.has-margin-bottom {
      padding-bottom: 80px;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  border-left: ${({ theme }) => theme.border};
  border-right: ${({ theme }) => theme.border};
  &.build {
    border-color: ${({ theme }) => theme.colors.build};
    ${StyledItem} {
      border-color: ${({ theme }) => theme.colors.build};
    }
  }
  &.birth {
    border-color: ${({ theme }) => theme.colors.birth};
    ${StyledItem} {
      border-color: ${({ theme }) => theme.colors.birth};
    }
  }
  &.seed {
    border-color: ${({ theme }) => theme.colors.seed};
    ${StyledItem} {
      border-color: ${({ theme }) => theme.colors.seed};
    }
  }
  ${media.lessThanIpadHorizontal`
    flex-direction: column;
    padding-bottom: ${({ theme }) => theme.structure.margin.medium}px;
    /* NOTE:
    newsの短い記事などの場合、
    高さが下まで伸びないので、指定する。
    layouts/index.tsx
    post/content.tsx
     */
    flex: 1;
  `}
`;

export default ContentColumn;
