import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSlug } from 'src/utils';
import { MenuList } from './menu-list';
import { GetFooterNavigationQuery } from 'graphql-types';

type Props = {};

type QueryProps = GetFooterNavigationQuery;

export const query = graphql`
  query GetFooterNavigation {
    prismic {
      japanese: home(lang: "ja-jp", uid: "home") {
        ...FooterLinks
      }
      english: home(lang: "en-us", uid: "home") {
        ...FooterLinks
      }
    }
  }
`;

export const Navigation: React.FC<Props> = () => {
  const {
    prismic: { japanese, english },
  } = useStaticQuery<QueryProps>(query);
  const { langKey } = useSlug();
  const list = React.useMemo(() => {
    if (langKey === 'ja') {
      return japanese;
    } else {
      return english;
    }
  }, [japanese, english, langKey]);
  if (!list) {
    return null;
  }
  return <MenuList {...list} />;
};

export default Navigation;
