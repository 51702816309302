import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useSlug } from 'src/utils';
import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import TitleSVG from 'src/images/logo.inline.svg';
import { GetSiteTitleQuery } from 'graphql-types';

type Props = {
  className?: string;
};

type QueryProps = GetSiteTitleQuery;

export const query = graphql`
  query GetSiteTitle {
    site {
      siteMetadata {
        title {
          en
        }
      }
    }
  }
`;

export const Title: React.FC<Props> = ({ className }: Props) => {
  const { site } = useStaticQuery<QueryProps>(query);
  const { langKey, isHome } = useSlug();
  return (
    <Wrapper className={className} as={isHome ? 'h1' : 'p'}>
      <StyledAnchor to={langKey === 'ja' ? '/' : `/${langKey}/`}>
        {site?.siteMetadata?.title?.en && (
          <ScreenReaderText>{site.siteMetadata.title?.en}</ScreenReaderText>
        )}
        <TitleSVG />
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  &:focus {
    outline: none;
  }
`;

const Wrapper = styled.h1`
  line-height: 1;
  width: 100%;
  ${media.ipadHorizontalOrMore`
    height: auto;
  `}
`;

export default Title;
