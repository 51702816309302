module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RE: DESCENTE","short_name":"RE: DESCENTE","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"re-descente-com","defaultLang":"ja-jp","langs":["ja-jp","en-us"],"shortenUrlLangs":true,"accessToken":"MC5YcVFPYXhFQUFDUUFhSWRn.77-977-977-977-9W3ZDFe-_vQPvv73vv73vv71477-977-9bO-_ve-_vWvvv73vv70eejk9DO-_vUJf77-977-9","path":"/preview","previews":true,"omitPrismicScript":true,"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-localization/gatsby-browser.js'),
      options: {"plugins":[],"languages":["ja","en"],"namespaces":["translation"],"localesDir":"./src/locales","allowIndex":true,"defaultLng":"ja","embedTranslations":{"preloadFallbackLng":true,"preloadNamespaces":[{"regex":"/en/","namespaces":["translation"],"languages":["en"]}]},"i18next":{"fallbackLng":"ja","debug":false},"i18nPlugin":{"langKeyDefault":"ja","useLangKeyLayout":false,"prefixDefault":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NG8FBPP","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
