import * as React from 'react';
import { TranslationDispatchContext } from 'src/contexts/translation-context';
import { translationLinkResolver } from './translation-link-resolver';
import { LangKeyType } from 'custom-types';

type UseTranslationLinkProps = {
  slug: string;
  langKey: LangKeyType;
  uid?: string | null;
  type?: string | null;
};

export function useTranslationLink({
  slug,
  langKey,
  uid,
  type,
}: UseTranslationLinkProps) {
  const translationLinkResolverArgs = React.useMemo(
    () => ({
      slug,
      langKey,
      uid: uid || undefined,
      type: type || undefined,
    }),
    [slug, langKey, uid, type]
  );
  const translationLink = translationLinkResolver(translationLinkResolverArgs);
  const { dispatch } = React.useContext(TranslationDispatchContext);
  React.useEffect(() => {
    if (dispatch) {
      dispatch({ type: 'set', payload: { translationLink } });
    }
  }, [dispatch, translationLink]);
}
