import * as React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  title?: string;
  isSingle?: boolean;
  children?: React.ReactNode;
};

export const PageHeader: React.FC<Props> = ({
  className,
  title,
  isSingle = true,
  children,
}: Props) => (
  <Wrapper className={className || ''}>
    {title && <Title as={isSingle ? 'p' : 'h1'}>{title}</Title>}
    {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
  </Wrapper>
);

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.enLetterSpacing};
`;

const ChildrenWrapper = styled.div`
  margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
  letter-spacing: ${({ theme }) => theme.enLetterSpacing};
`;

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.helvetica};
`;

export default PageHeader;
