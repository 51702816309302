import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import { MenuListItem } from 'src/components/common/menu-list-item';
import { Anchor } from 'src/components/common/atoms';
import { FooterLinksFragment } from 'graphql-types';

type Props = FooterLinksFragment;

export const query = graphql`
  fragment FooterLinks on PRISMIC_Home {
    footerLinks: footer_links {
      footerLinkTitle: footer_link_title
      footerLink: footer_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
  }
`;

export const MenuList: React.FC<Props> = ({ footerLinks }: Props) => {
  if (!footerLinks) {
    return null;
  }
  return (
    <Wrapper>
      {footerLinks.map((link, index) => (
        <StyledMenuListItem key={`footer-menu-${index}`}>
          <Anchor to={link?.footerLink?.url || ''}>
            {link.footerLinkTitle && RichText.asText(link.footerLinkTitle)}
          </Anchor>
        </StyledMenuListItem>
      ))}
    </Wrapper>
  );
};

const StyledMenuListItem = styled(MenuListItem)`
  text-transform: uppercase;
  ${media.lessThanIpadHorizontal`
    & + & {
      margin-top: ${({ theme }) =>
        theme.structure.margin.xSmallWithLargeText}px;
    }
  `}
  ${media.ipadHorizontalOrMore`
    & + & {
      margin-left: 1.4em;
    }
  `}
  ${media.ipadProHorizontalOrMore`
    & + & {
      margin-left: 2em;
    }
  `}
`;

const Wrapper = styled.ul`
  ${media.lessThanIpadHorizontal`
    padding-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
    padding-bottom: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  `}
  ${media.ipadHorizontalOrMore`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: ${({ theme }) => theme.headerHeight.pc}px;
  `}
`;

export default MenuList;
