import * as React from 'react';
import {
  LoaderContext,
  LoaderDispatchContext,
} from 'src/contexts/loader-context';

export function useLoader() {
  return React.useContext(LoaderContext);
}

export function useLoaderFetchingDispatch(isFetching: boolean) {
  const { dispatch } = React.useContext(LoaderDispatchContext);
  React.useEffect(() => {
    if (dispatch === null) {
      return;
    }

    dispatch({ type: 'set', payload: { isFetching } });
  }, [dispatch, isFetching]);
}

export default useLoader;
