import { LinkResolver } from 'prismic-reactjs';
import birthSeasonsJa from 'src/data/seasons/birth-seasons-ja.json';
import birthSeasonsEn from 'src/data/seasons/birth-seasons-en.json';
import seedSeasonsJa from 'src/data/seasons/seed-seasons-ja.json';
import seedSeasonsEn from 'src/data/seasons/seed-seasons-en.json';

type ProductSeasonsType = {
  [key: string]: string;
};

export const linkResolver: LinkResolver = (document) => {
  const { type, uid, link_type, lang } = document;
  const langPath = lang === 'ja-jp' ? '' : '/en';
  const langKey = lang === 'ja-jp' ? 'ja' : 'en';
  if (type === 'post') {
    return `${langPath}/news/${uid}/`;
  } else if (type === 'birth') {
    let season = null;
    if (langKey === 'ja') {
      season = birthSeasonsJa?.hasOwnProperty(uid)
        ? (birthSeasonsJa as ProductSeasonsType)[uid]
        : null;
    } else {
      season = birthSeasonsEn?.hasOwnProperty(uid)
        ? (birthSeasonsEn as ProductSeasonsType)[uid]
        : null;
    }
    if (season) {
      return `${langPath}/birth/${season}/${uid}/`;
    } else {
      if (langKey === 'ja') {
        return '/';
      } else {
        return '/en/';
      }
    }
  } else if (type === 'seed') {
    let season = null;
    if (langKey === 'ja') {
      season = seedSeasonsJa?.hasOwnProperty(uid)
        ? (seedSeasonsJa as ProductSeasonsType)[uid]
        : null;
    } else {
      season = seedSeasonsEn?.hasOwnProperty(uid)
        ? (seedSeasonsEn as ProductSeasonsType)[uid]
        : null;
    }
    if (season) {
      return `${langPath}/seed/${season}/${uid}/`;
    } else {
      if (langKey === 'ja') {
        return '/';
      } else {
        return '/en/';
      }
    }
  } else if (type === 'home') {
    return '${langKey}/';
  } else if (link_type === 'Document') {
    return `${langPath}/${uid}/`;
  }
  if (langKey === 'ja') {
    return '/';
  } else {
    return '/en/';
  }
};
