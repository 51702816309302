import * as React from 'react';
import styled from 'styled-components';
import { Link, GatsbyLinkProps } from 'gatsby';

type Props = JSX.IntrinsicElements['a'] &
  GatsbyLinkProps<{}> & {
    className?: string;
    children: React.ReactNode;
  };

export const Anchor: React.FC<Props> = ({
  className,
  to,
  children,
  ['aria-label']: ariaLabel,
  activeClassName,
}: Props) => {
  const isInternal = to?.startsWith('/');
  if (isInternal) {
    return (
      <StyledLink
        className={className || ''}
        to={to}
        aria-label={ariaLabel || ''}
        activeClassName={activeClassName}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <a
        className={className || ''}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel || ''}
      >
        {children}
      </a>
    );
  }
};

const StyledLink = styled(Link)`
  &:focus {
    outline: none;
  }
`;

export default Anchor;
