import React from 'react';

export const initialValue = {
  translationLink: null,
};

type TranslationContextType = {
  translationLink: string | null;
};

export const TranslationContext = React.createContext<TranslationContextType>(
  initialValue
);

type ReducerType = { type: string; payload: TranslationContextType };

type DispatchContextType = {
  dispatch: React.Dispatch<ReducerType> | null;
};

export const TranslationDispatchContext = React.createContext<
  DispatchContextType
>({
  dispatch: null,
});

export const TranslationReducer: React.Reducer<
  TranslationContextType,
  ReducerType
> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('TranslationReducer error');
  }
};

export default TranslationContext;
