// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-birth-tsx": () => import("./../src/templates/birth.tsx" /* webpackChunkName: "component---src-templates-birth-tsx" */),
  "component---src-templates-build-tsx": () => import("./../src/templates/build.tsx" /* webpackChunkName: "component---src-templates-build-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-process-tsx": () => import("./../src/templates/process.tsx" /* webpackChunkName: "component---src-templates-process-tsx" */),
  "component---src-templates-seed-tsx": () => import("./../src/templates/seed.tsx" /* webpackChunkName: "component---src-templates-seed-tsx" */),
  "component---src-templates-stores-tsx": () => import("./../src/templates/stores.tsx" /* webpackChunkName: "component---src-templates-stores-tsx" */)
}

