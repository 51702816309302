export const translationLinkResolver = ({
  slug,
  langKey,
  type,
  uid: transitionLinkUid,
}: {
  slug: string;
  langKey: string;
  type?: string | null;
  uid?: string | null;
}): string => {
  const targetLangPathname = langKey === 'ja' ? '/en' : '';
  if (transitionLinkUid) {
    if (type === 'post') {
      return `${targetLangPathname}/news/${transitionLinkUid}/`;
    } else if (type === 'birth' || type === 'seed') {
      const pathArray = slug.replace(/^\/en/, '').split('/');
      let lastPath = pathArray.pop();
      if (!lastPath) {
        lastPath = pathArray.pop();
      }
      let newPathname = pathArray.join('/');
      if (targetLangPathname !== '') {
        newPathname = `${targetLangPathname}${newPathname}`;
      }
      return `${newPathname}/${transitionLinkUid}/`;
    } else {
      if (langKey === 'ja') {
        return `/en${slug}/`;
      } else {
        return slug?.replace(/\/en/, '');
      }
    }
  }
  if (langKey === 'ja') {
    return '/en/';
  } else {
    return '/';
  }
};
