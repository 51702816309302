import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useSlug } from 'src/utils';
import { GetSeoQuery } from 'graphql-types';

type MetaProps = JSX.IntrinsicElements['meta'];
type Props = {
  title?: string | null;
  description?: string | null;
  meta?: MetaProps[];
  image?: string;
};

const query = graphql`
  query GetSeo {
    site {
      siteMetadata {
        title {
          ja
          en
        }
        description {
          ja {
            home
          }
          en {
            home
          }
        }
        author
        siteUrl
        twitter
        facebookAppId
        banner
      }
    }
  }
`;

export const SEO: React.FC<Props> = ({
  title,
  description,
  meta,
  image,
}: Props) => {
  const { slug, langKey } = useSlug();
  const { site } = useStaticQuery<GetSeoQuery>(query);
  const isJapanese = langKey === 'ja';
  const titleJa = site?.siteMetadata?.title?.ja;
  const titleEn = site?.siteMetadata?.title?.en;
  const defaultTitle = isJapanese ? `${titleJa} | ${titleEn}` : titleEn;
  const pageTitle = title || defaultTitle || '';
  const defaultDescription = isJapanese
    ? site?.siteMetadata?.description?.ja?.home
    : site?.siteMetadata?.description?.en?.home;
  const metaDescription = description || defaultDescription || '';
  const url = `${site?.siteMetadata?.siteUrl}${slug}`;
  const seoImage = image
    ? `${site?.siteMetadata?.siteUrl}${image}`
    : `${site?.siteMetadata?.siteUrl}/${site?.siteMetadata?.banner}`;

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: seoImage,
    },
    {
      property: `fb:app_id`,
      content: site?.siteMetadata?.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site?.siteMetadata?.author,
    },
    {
      name: `twitter:title`,
      content: pageTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: seoImage,
    },
  ] as MetaProps[];

  return (
    <Helmet
      htmlAttributes={{
        lang: langKey,
      }}
      title={pageTitle}
      meta={meta ? [...defaultMeta, ...meta] : defaultMeta}
    />
  );
};

export default SEO;
