import * as React from 'react';
import GatsbyImage, { FluidObject, FixedObject } from 'gatsby-image';
import styled from 'styled-components';
import {
  GatsbyImageSharpFluid_WithWebpFragment,
  GatsbyImageSharpFluid_WithWebp_NoBase64Fragment,
  GatsbyImageSharpFixed_WithWebpFragment,
  GatsbyImageSharpFixed_WithWebp_NoBase64Fragment,
} from 'graphql-types';

type FluidType =
  | (GatsbyImageSharpFluid_WithWebpFragment & Pick<FluidObject, 'media'>)
  | (GatsbyImageSharpFluid_WithWebp_NoBase64Fragment &
      Pick<FluidObject, 'media'>)
  | null;

type FixedType =
  | (GatsbyImageSharpFixed_WithWebpFragment & Pick<FluidObject, 'media'>)
  | (GatsbyImageSharpFixed_WithWebp_NoBase64Fragment &
      Pick<FluidObject, 'media'>)
  | null;

export type Props = {
  className?: string;
  fluid?: FluidType | FluidType[];
  fixed?: FixedType | FixedType[];
  alt?: string;
  loading?: 'auto' | 'lazy' | 'eager';
  sizes?: string;
};

export const Img: React.FC<Props> = ({
  className,
  fluid,
  fixed,
  alt,
  loading = 'lazy',
  sizes,
}: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [wrapperClass, setWrapperClass] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setLoaded(true);
    if (wrapperRef.current) {
      const image = wrapperRef.current.querySelector('img');
      if (image) {
        const ratio = image.naturalWidth / image.naturalHeight;
        setWrapperClass(ratio > 1 ? 'horizontal-image' : 'vertical-image');
      }
    }
  }, []);
  return (
    <div
      className={`${className || ''} ${wrapperClass || ''}`}
      ref={wrapperRef}
    >
      {fluid && (
        <StyledGatsbyImg
          className={`${loaded ? 'loaded' : ''}`}
          fluid={!sizes ? (fluid as FluidObject | FluidObject[]) : undefined}
          alt={alt}
          fadeIn={true}
          durationFadeIn={100}
          onLoad={onLoad}
          loading={loading}
          sizes={
            sizes
              ? {
                  ...(fluid as FluidObject),
                  sizes,
                }
              : undefined
          }
        />
      )}
      {fixed && (
        <StyledGatsbyImg
          className={`${loaded ? 'loaded' : ''}`}
          fixed={fixed as FixedObject | FixedObject[]}
          alt={alt}
          fadeIn={true}
          durationFadeIn={100}
          onLoad={onLoad}
          loading={loading}
        />
      )}
    </div>
  );
};

const StyledGatsbyImg = styled(GatsbyImage)`
  &:not(.loaded) {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export default Img;
