import React from 'react';

export const initialValue = {
  build: 0,
  birth: 0,
  seed: 0,
  post: 0,
};

type ScrollTopContextType = typeof initialValue;

export const ScrollTopContext = React.createContext<ScrollTopContextType>(
  initialValue
);

type ReducerType = { type: string; payload: Partial<ScrollTopContextType> };

type DispatchContextType = {
  dispatch: React.Dispatch<ReducerType> | null;
};

export const ScrollTopDispatchContext = React.createContext<
  DispatchContextType
>({
  dispatch: null,
});

export const ScrollTopReducer: React.Reducer<
  ScrollTopContextType,
  ReducerType
> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('ScrollTopReducer error');
  }
};

export default ScrollTopContext;
