import * as React from 'react';
import styled from 'styled-components';
import DiagonalLine from 'src/images/diagonal-line.inline.svg';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const HoverEffect: React.FC<Props> = ({
  className,
  children,
}: Props) => (
  <Wrapper className={className || ''}>
    {children}
    <DiagonalLineWrapper>
      <DiagonalLine />
    </DiagonalLineWrapper>
  </Wrapper>
);

const DiagonalLineWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  pointer-events: none;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  a,
  button {
    &:hover,
    &.focus-visible {
      & + ${DiagonalLineWrapper} {
        display: block;
      }
    }
    &:focus-visible {
      & + ${DiagonalLineWrapper} {
        display: block;
      }
    }
  }
`;

export default HoverEffect;
