import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/common/atoms';

type Props = {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isPressed: boolean;
  targetId?: string;
  ariaLabel?: string;
};

export const MenuButton: FC<Props> = ({
  className,
  onClick,
  isPressed,
  targetId,
  ariaLabel = 'main menu',
}: Props) => (
  <StyledMenuButton
    className={className}
    onClick={onClick}
    aria-expanded={isPressed}
    aria-pressed={isPressed}
    aria-label={ariaLabel}
    aria-controls={targetId}
  >
    <Line />
    <Line />
    <Line />
  </StyledMenuButton>
);

const Line = styled.i`
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  background-color: black;
`;

const StyledMenuButton = styled(Button)`
  --button-height: 24px;
  --border-size: 2px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  width: 30px;
  height: var(--button-height);
  display: flex;
  align-items: center;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &.focus-visible {
    transform: scale(1.1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }

  ${Line} {
    position: absolute;
    left: 0;
    height: var(--border-size);
  }

  &[aria-pressed='false'] {
    ${Line} {
      &:nth-of-type(1) {
        top: 5%;
      }
      &:nth-of-type(2) {
        /* top: calc((var(--button-height) / 2) - (var(--border-size) / 2)); */
        top: 11px;
      }
      &:nth-of-type(3) {
        bottom: 5%;
      }
    }
  }

  &[aria-pressed='true'] {
    ${Line} {
      &:nth-of-type(1) {
        top: 0;
        transform: translateY(
            calc(calc(var(--button-height) / 2) - calc(var(--border-size) / 2))
          )
          rotate(-215deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        bottom: 0;
        transform: translateY(
            calc(
              (calc(var(--button-height) / 2) - calc(var(--border-size) / 2)) *
                -1
            )
          )
          rotate(215deg);
      }
    }
    &:hover {
      ${Line} {
        &:nth-of-type(1) {
          transform: translateY(
              calc(
                calc(var(--button-height) / 2) - calc(var(--border-size) / 2)
              )
            )
            rotate(-45deg);
        }

        &:nth-of-type(3) {
          transform: translateY(
              calc(
                (calc(var(--button-height) / 2) - calc(var(--border-size) / 2)) *
                  -1
              )
            )
            rotate(45deg);
        }
      }
    }
  }
`;
