import React from 'react';

export const initialValue = {
  isFetching: true,
};

type LoaderContextType = typeof initialValue;

export const LoaderContext = React.createContext<LoaderContextType>(
  initialValue
);

type ReducerType = { type: string; payload: LoaderContextType };

type DispatchContextType = {
  dispatch: React.Dispatch<ReducerType> | null;
};

export const LoaderDispatchContext = React.createContext<DispatchContextType>({
  dispatch: null,
});

export const LoaderReducer: React.Reducer<LoaderContextType, ReducerType> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('LoaderReducer error');
  }
};

export default LoaderContext;
