import * as React from 'react';
import styled from 'styled-components';
import { useSlug } from 'src/utils';
import { TranslationContext } from 'src/contexts/translation-context';
import { Anchor } from 'src/components/common/atoms';

type Props = {
  className?: string;
};

function useLinks() {
  const { slug, langKey } = useSlug();
  const { translationLink } = React.useContext(TranslationContext);
  if (langKey === 'ja') {
    return {
      jaTo: slug,
      enTo: translationLink || '',
    };
  } else {
    return {
      jaTo: translationLink || '',
      enTo: slug,
    };
  }
}

export const Languages: React.FC<Props> = ({ className }: Props) => {
  const { jaTo, enTo } = useLinks();
  return (
    <Wrapper className={className || ''}>
      <li>
        <StyledAnchor
          to={jaTo}
          aria-label="Japanese"
          activeClassName={'active'}
        >
          JA
        </StyledAnchor>
      </li>
      <li>
        <Slash aria-hidden="true">/</Slash>
        <StyledAnchor to={enTo} aria-label="English" activeClassName={'active'}>
          EN
        </StyledAnchor>
      </li>
    </Wrapper>
  );
};

const Slash = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
`;

const StyledAnchor = styled(Anchor)`
  &.active {
    text-decoration: underline;
  }
`;

const Wrapper = styled.ul`
  display: flex;
`;

export default Languages;
