import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';
// NOTE:
// MenuListItemをcommon経由（from 'src/components/common'）でimportすると、
// styled(MenuListItem)でエラーが出るので、menu-list-itemからimportする。
// この辺りのバグ？
// https://github.com/styled-components/styled-components/issues/1449
//
import { MenuListItem } from 'src/components/common/menu-list-item';
import { MenuAnchor, Props as MenuAnchorProps } from './menu-anchor';
import { Languages } from './languages';

export type Props = {
  className?: string;
  list: MenuAnchorProps[] | null;
};

export const MenuList: React.FC<Props> = ({ className, list }: Props) => (
  <Wrapper className={className || ''}>
    {list &&
      list.map((item, index) => (
        <StyledMenuListItem key={`header-menu-${index}`}>
          <MenuAnchor {...item} />
        </StyledMenuListItem>
      ))}
    <StyledMenuListItem aria-label="Languages">
      <Languages />
    </StyledMenuListItem>
  </Wrapper>
);

const StyledMenuListItem = styled(MenuListItem)`
  /* ${media.ipadHorizontalOrMore`
    & + & {
      margin-left: 1.4em;
    }
  `}
  ${media.ipadProHorizontalOrMore`
    & + & {
      margin-left: 2em;
    }
  `} */
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${media.ipadHorizontalOrMore`
    flex-direction: row;
  `}
`;

export default MenuList;
