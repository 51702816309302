import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  /* ::-webkit-scrollbar {
    width: 4px;
  } */

  /* ::-webkit-scrollbar-track {
  } */

  /* ::-webkit-scrollbar-thumb {
    background: black;
  } */

  ::selection {
    background: #ccc;
  }

  html {
    font-size: 62.5%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.main};
    font-weight: 600;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* 
    flexや、gridだと上記だけでは、長い単語がはみ出すため、
    word-break: break-word;を追加。
    word-break: break-all;（或いは、overflow-wrap: anywhere）、などにすると、
    全ての禁足処理が無効化されるので、word-break: break-word;にしておく。
    */
    word-break: break-word;
    hyphens: auto;

    /* for react modal for mobile*/
    &.ReactModal__Body--open {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: white;
    border: 1px solid black;
    line-height: 1.4;
    font-family: inherit;
    font-size: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &[href=""] {
      pointer-events: none;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &.focus-visible {
      outline: 1px solid black;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

export default GlobalStyle;
