import * as React from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useInView } from 'react-intersection-observer';
import Player from '@vimeo/player';
import styled from 'styled-components';
import { useIsMobile } from 'src/utils';
import { Controller } from './controller';

type Props = {
  className?: string;
  videoId: string;
  isAuto?: boolean;
  isFit?: boolean;
  hasController?: boolean;
};

type UsePlayerProps = Pick<Props, 'videoId' | 'isAuto'>;
function usePlayer({ videoId, isAuto }: UsePlayerProps) {
  const isMobile = useIsMobile();
  const [ratio, setRatio] = React.useState(16 / 9);
  const [loaded, setLoaded] = React.useState(false);
  const playerWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<Player | null>(null);
  React.useEffect(() => {
    function onLoaded() {
      if (!playerWrapperRef.current) {
        return;
      }
      const iframe = playerWrapperRef.current.querySelector('iframe');
      if (iframe?.width && iframe?.height) {
        const iframeWidth = parseInt(iframe.width, 10);
        const iframeHeight = parseInt(iframe.height, 10);
        const ratio = iframeWidth / iframeHeight;
        setRatio(ratio);
        setLoaded(true);
      }
    }

    if (playerWrapperRef.current) {
      playerRef.current = new Player(videoId, {
        id: parseInt(videoId, 10),
        background: isAuto ? true : false,
        width: 640,
      });
      playerRef.current.on('loaded', onLoaded);
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.off('loaded', onLoaded);
        playerRef.current.destroy();
      }
    };
  }, [videoId, isAuto, isMobile]);

  return {
    playerWrapperRef,
    playerRef,
    ratio,
    loaded,
  };
}

function getPlayerSize(
  ratio: number,
  wrapperWidth: number,
  wrapperHeight: number
): { width: number; height: number } {
  // NOTE:
  // モバイルでのトップページの１番上の動画（下に来る動画は大丈夫）だけ、
  // ループの際、なぜか動画がスタート位置に戻る時に、一瞬右に数ピクセルずれる問題が発生。
  // 色々動作確認した結果、どうも動画のwidthが関係しているようで、
  // 目的の高さ（現状は、234px）から割り出される幅（現状は416px）だとその現象が起こる。
  // （ただ、他の幅でも起こる場合があり、横にずれたり、縦にずれたりするパターンがある）
  // 元の高さの近似値で+2px（この場合236px）で割り出される幅（419.5555...px）だと起こらないので、
  // とりあえず、+2pxしておく。
  // 追記：+2pxだと、iPhone8+で現象が発生するっぽいので、変更。
  // 大きいサイズだとズレが出にくい傾向があるので、とりあえず大きめにする。
  const adjustHeight = wrapperHeight + 50;
  const width = ratio * adjustHeight;
  if (width < wrapperWidth) {
    return getPlayerSize(ratio, wrapperWidth, adjustHeight + 20);
  }
  return { width, height: adjustHeight };
}

function usePlayerSize({ ratio }: { ratio: number }) {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  const [playerSize, setPlayerSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    let wrapperWidth = width;
    let wrapperHeight = height;
    if (ref.current && width === 1) {
      wrapperWidth = ref.current.clientWidth;
      wrapperHeight = ref.current.clientHeight;
    }
    setPlayerSize(getPlayerSize(ratio, wrapperWidth, wrapperHeight));
  }, [ref, ratio, width, height]);
  return {
    wrapperResizeObserverRef: ref,
    wrapperWidth: width,
    playerSize,
  };
}

function useManualControl() {
  const [isPlayManual, setIsPlayManual] = React.useState(true);
  const onClickPlay = React.useCallback(() => {
    setIsPlayManual(true);
  }, []);
  const onClickPause = React.useCallback(() => {
    setIsPlayManual(false);
  }, []);
  return { isPlayManual, onClickPlay, onClickPause };
}

function useAutoControl() {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [isPlayAuto, setIsPlayAuto] = React.useState(true);
  React.useEffect(() => {
    if (inView) {
      setIsPlayAuto(true);
    } else {
      setIsPlayAuto(false);
    }
  }, [inView]);
  return { ref, isPlayAuto };
}

function useIsPlay({
  playerRef,
  isPlayManual,
  isPlayAuto,
  loaded,
}: {
  playerRef: React.MutableRefObject<Player | null>;
  isPlayManual: boolean;
  isPlayAuto: boolean;
  loaded: boolean;
}) {
  const [isPlay, setIsPlay] = React.useState(true);
  React.useEffect(() => {
    if (isPlayManual) {
      setIsPlay(isPlayAuto);
    } else {
      setIsPlay(isPlayManual);
    }
  }, [isPlayManual, isPlayAuto]);
  React.useEffect(() => {
    if (loaded && playerRef.current) {
      if (isPlay) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [loaded, playerRef, isPlay]);
  return {
    isPlay,
  };
}

export const Video: React.FC<Props> = ({
  className,
  videoId,
  isAuto = true,
  isFit = false,
  hasController = false,
}: Props) => {
  const wrapperId = `video-wrapper-${videoId}`;

  // init
  const { playerWrapperRef, playerRef, ratio, loaded } = usePlayer({
    videoId,
    isAuto,
  });

  // player size
  const { wrapperResizeObserverRef, wrapperWidth, playerSize } = usePlayerSize({
    ratio,
  });

  // controller
  const { isPlayManual, onClickPlay, onClickPause } = useManualControl();
  const { ref: intersectionObserverRef, isPlayAuto } = useAutoControl();
  const { isPlay } = useIsPlay({ playerRef, isPlayManual, isPlayAuto, loaded });
  return (
    <Wrapper
      id={wrapperId}
      className={`${className || ''} ${isFit ? 'fit' : ''} ${
        loaded ? 'loaded' : ''
      }`}
      ref={wrapperResizeObserverRef}
      role="region"
      customHeight={wrapperWidth / ratio}
    >
      <div ref={intersectionObserverRef}>
        <PlayerWrapper
          id={videoId}
          ref={playerWrapperRef}
          style={{ width: playerSize.width, height: playerSize.height }}
        />
        {hasController && (
          <StyledController
            isPlay={isPlay}
            onPlay={onClickPlay}
            onPause={onClickPause}
            targetId={wrapperId}
          />
        )}
      </div>
    </Wrapper>
  );
};

const StyledController = styled(Controller)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PlayerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type WrapperProps = { customHeight: number };
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 100%;
  &:not(.fit) {
    height: ${({ customHeight }: WrapperProps) => `${customHeight}px`};
    &:not(.loaded) {
      height: 0;
      padding-bottom: 56.25%;
      ${PlayerWrapper} {
        height: 100%;
      }
    }
  }

  iframe {
    height: 100%;
    pointer-events: none;
    width: 100%;
  }
`;

export default Video;
