import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useIsMobile, useSlug } from 'src/utils';
import { Margin } from '../margin';
import { Title } from './title';
import { Navigation } from './navigation';
import { MenuButton } from '../menu-button';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className }: Props) => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClick = React.useCallback(() => {
    setIsOpen((value) => !value);
  }, []);
  const { slug } = useSlug();
  React.useEffect(() => {
    setIsOpen(false);
  }, [slug]);
  const navigationId = 'site-navigation';
  return (
    <Wrapper className={className || ''} role="banner">
      {isMobile && (
        <HeaderMobileMargin>
          <Flex>
            <StyledTitle />
            <StyledMenuButton
              onClick={onClick}
              targetId={navigationId}
              isPressed={isOpen}
            />
          </Flex>
          {isOpen && <StyledMobileNavigation id={navigationId} />}
        </HeaderMobileMargin>
      )}
      {!isMobile && (
        <Margin>
          <Flex>
            <StyledTitle />
            <StyledPcNavigation id={navigationId} />
          </Flex>
        </Margin>
      )}
    </Wrapper>
  );
};

const StyledTitle = styled(Title)`
  max-width: 170px;
  ${media.ipadHorizontalOrMore`
    max-width: 191px;
  `}
`;

const StyledMobileNavigation = styled(Navigation)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: ${({ theme }) => theme.structure.margin.xSmall}px;
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-bottom: 80px;
  border: ${({ theme }) => theme.border};
`;

const StyledMenuButton = styled(MenuButton)`
  border: none;
  position: relative;
  z-index: 11;
  display: none;
  ${media.lessThanIpadHorizontal`
    display: block;
  `}
`;

const HeaderMobileMargin = styled.div`
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
`;

const StyledPcNavigation = styled(Navigation)`
  flex-grow: 1;
  max-width: 1000px;
  padding-left: 4em;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.lessThanIpadHorizontal`
    height: ${({ theme }) => theme.headerHeight.sp}px;
  `}
  ${media.ipadHorizontalOrMore`
    height: ${({ theme }) => theme.headerHeight.pc}px;
    
  `}
`;

const Wrapper = styled.header`
  background-color: white;
  border: ${({ theme }) => theme.border};
`;

export default Header;
