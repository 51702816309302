import * as React from 'react';
import styled from 'styled-components';
import { media } from 'src/styles';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const MenuListItem: React.FC<Props> = ({
  className,
  children,
}: Props) => <Wrapper className={className || ''}>{children}</Wrapper>;

const Wrapper = styled.li`
  font-family: ${({ theme }) => theme.font.family.helvetica};
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  * {
    letter-spacing: ${({ theme }) => theme.enLetterSpacing};
    line-height: 1;
    font-weight: 600;
    &:focus {
      outline: none;
    }
  }
  a {
    text-decoration: none;
    &:hover,
    &.focus-visible {
      text-decoration: underline;
    }
    &:focus-visible {
      text-decoration: underline;
    }
  }
`;

export default MenuListItem;
